.date-picker-wrapper .MuiInputAdornment-positionEnd > button {
    margin-right: -9px;
}

.add-event-form .inValid:before {
    border-bottom: 1px solid red !important;
}

.MuiPickersDay-daySelected {
    color: #fff;
    font-weight: 500;
    background-color: #2F72F2 !important;
}