/* .inValid:before {
    border-bottom: 1px solid red;
} */

.valid {
    /* border-bottom: 1px solid green; */
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #2F72F2 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked:hover {
    color: #2F72F2;
}



/* ======== Detail Item Style Section ==========*/


.MuiPopover-paper ul.MuiMenu-list > li .detail-item {
    width: 100%;
    max-width: calc(25vw + 40px);
    margin: 0 auto;
    background: #F5FCFF 0% 0% no-repeat padding-box;
    border: 1px solid #70707039;
    border-radius: 3px;
    padding: 8px 16px;
}
.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-heading {
    color: #4D4F5C;
    font-size: 14px;
    line-height: 25px;
    font-weight: bold;
    padding-bottom: 10px;
}
.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    margin: 0;
    justify-content: space-between;
}

.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row > .item-col {
    width: 50%;
    margin-bottom: 14px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1em;
}
.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row > .item-col.full-width {
    width: 100%;
}
.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row > .item-col > span.text {
    color: #815739;
    font-size: 12px;
    line-height: 16px;
}

.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row > .item-col > span.value {
    color: #484848;
    font-size: 14px;
    line-height: 20px;
}

.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row > .item-col > span.value.white-space-normal {
    white-space: normal;
}

.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row > .item-col:nth-child(2) ~ .item-col {
    margin-bottom: 5px;
}

.MuiSelect-root .item-col {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row > .item-col:nth-child(even) {
    padding-left: 10px;
}

.MuiPopover-paper ul.MuiMenu-list > li .detail-item .item-row > .item-col > span {
    padding-right: 5px;
} */

/* ======== End Section Detail Item Style Section ==========*/

