$primary-color: #03306c;
$text-color: #323338;
$bg-color: #ebf6ff;
$white-color: #ffffff;
$border-color: #707070;

@import "loaders.css/src/animations/ball-pulse.scss";

html,
body {
  font-size: 14px;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .MuiTooltip-tooltip {
    background-color: #fff;
    font-size: 11px;
    color: rgba($color: #323338, $alpha: 0.8);
    border: 1px solid #a8bad0;
    .MuiTooltip-arrow {
      &::before {
        background-color: #fff;
        border: 1px solid #a8bad0;
      }
    }
  }
}

.loader {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1999;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: flex;
}

// Material UI Overwrite Css
.app-body {
  min-height: 100vh;
  background: url(./assets/bottom-bg.svg) repeat-x bottom center,
    url(./assets/left-bg.svg) no-repeat 10% 30%,
    url(./assets/right-bg.svg) no-repeat 90% 30%;
  background-color: $bg-color;
  &.inner-page {
    background-color: $white-color;
  }
  &.not-found {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &::before {
    width: 100%;
    height: 5px;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: $primary-color;
  }
  .MuiPaper-root.custom-paper {
    color: $text-color;
    border: 1px solid #e3efff;
    border-radius: 3px;
    background-color: $white-color;
    box-shadow: 5px 5px 20px $bg-color;
    &.pay-off-paper {
      background-color: #ebf6ff;
    }
  }
  .MuiTypography-body2 {
    a {
      color: #0076eb;
    }
  }
  .MuiTypography-h1 {
    font-size: 1.29rem;
    font-weight: 400;
    //margin-bottom:2rem;
  }
  .MuiButton-contained,
  .MuiButton-text {
    font-size: 1rem;
    border-radius: 30px;
    font-weight: normal;
    &.MuiButton-containedPrimary {
      background-color: $primary-color;
      color: $white-color;
      border-radius: 30px;
    }
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
  .MuiFormLabel-root {
    &.Mui-error {
      color: inherit;
    }
  }
}

// Header Css
.header {
  &.MuiAppBar-colorPrimary {
    background-color: #ffffff;
    color: $primary-color;
    border-top: 5px solid $primary-color;
    box-shadow: 5px 5px 20px $bg-color;
  }
  .MuiToolbar-regular {
    min-height: 55px;
  }
  .tool-bar {
    justify-content: space-around;
    .logo-img {
      height: 40px;
      cursor: pointer;
    }
    .MuiAvatar-circle {
      border: 1px solid rgba($color: $border-color, $alpha: 0.25);
      background-color: $bg-color;
      color: $primary-color;
    }
  }
}

// Global Search Css
.main-container {
  min-height: 100vh;
  @media only screen and (max-width: 991px) {
    padding-top: 25px;
    &.MuiGrid-align-items-xs-center {
      align-items: flex-start;
    }
  }
  .dashboard-container {
    width: 90%;
    max-width: 480px;
    &.loan-pg-wrapper {
      max-width: 835px;

      .MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(0.85);
      }
    }
    .dashboard-paper {
      border: 1px solid $bg-color;
      box-shadow: 0 0 25px #a7bbd2;
      border-radius: 10px;
      padding: 45px;
      @media only screen and (max-width: 1440px) {
        padding: 30px 40px;
      }
      @media only screen and (max-width: 600px) {
        padding: 30px 18px;
      }
      .logo-box {
        text-align: center;
        margin-bottom: 60px;
        @media only screen and (max-width: 1440px) {
          margin-bottom: 50px;
        }
        @media only screen and (max-width: 600px) {
          margin-bottom: 25px;
        }
        img {
          height: 50px;
        }
      }
      .grid-row {
        display: flex;
        align-items: flex-end;
        margin-bottom: 40px;
        .captcha-box {
          flex: 1;
          img {
            border: 1px solid #a8bad0;
            border-radius: 2px;
            max-width: 100%;
            height: 40px;
          }
        }
        .icon-box {
          width: 16px;
          text-align: right;
        }
      }
      .MuiFormControl-root {
        flex: 1;
        margin-right: 25px;
        .MuiInputLabel-formControl {
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          &.Mui-error {
            color: inherit;
          }
        }
      }
      .MuiFormHelperText-root.Mui-error {
        position: absolute;
        top: 100%;
        white-space: nowrap;
      }
    }
  }
}
.help-text {
  padding-top: 13px;
  text-align: center;
  &.inner-page {
    max-width: 80%;
    margin: 0 auto;
  }
}
// Sub Header
.sub-header {
  background-color: $primary-color;
  z-index: 1101;
  position: relative;
  color: $white-color;
  display: flex;
  min-height: 66px;
  &.subheader-bg{
    flex-direction: column;
    background: transparent url(./assets/subheader-bg--.png) repeat-x bottom center;
    padding-left: 1rem;
    background-size: cover;
    z-index: 99999;
    .MuiTypography-h5{
      padding-top: 0.75rem;
      line-height: 1;
    }
  }
  .back-button {
    width: 60px;
    color: $white-color;
  }
  .sub-header-list {
    display: flex;
    // flex: 1;
    overflow-y: auto;
    .sub-header-list-item {
      width: auto;
      white-space: nowrap;
      padding: 0 16px;
      @media only screen and (max-width: 1280px) {
        padding: 0 10px;
      }
      .MuiTypography-body1 {
        font-size: 0.85rem;
        font-weight: bold;
        color: inherit;
      }
      .MuiTypography-body2 {
        color: inherit;
        font-size: 1rem;
        display: flex;
        > span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
          @media only screen and (max-width: 1366px) {
            max-width: 120px;
          }
        }
      }
      @media only screen and (max-width: 1280px) {
        &.hide-on-small {
          display: none;
        }
      }
    }
  }
  .show-on-small {
    display: none;
    padding: 0 10px;
    @media only screen and (max-width: 1280px) {
      display: flex;
      align-items: center;
    }
  }
}

.application-detail-container {
  display: flex;
  height: calc(100vh - 133px);
  &.loan-pg-inner-wrapper {
    flex-direction: column;
    height: 100%;

    &:last-child {
      padding-bottom: 50px;
    }
  }
  .detail-section {
    flex: 1;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    .detail-heading {
      padding-bottom: 25px;
    }
  }
}

.heading-section {
  min-height: 42px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .MuiIconButton-root {
    padding: 6px;
  }
  .heading-title {
    flex: 1;
  }
  .heading-buttons {
    button {
      margin-left: 10px;
    }
  }
}

.link {
  color: #4583fe;
  cursor: pointer;
  font-weight: 500;
}

.MuiIconButton-root {
  &.popover-icon {
    color: inherit;
    padding: 0 0 0 3px;
    font-size: inherit;
    font-weight: 500;
  }
  &.square-icon-button {
    border-radius: 3px;
    border: 1px solid rgba($color: #707070, $alpha: 0.25);
    background-color: #ebf6ff;
  }
  &.popover-button {
    width: 35px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid rgba($color: #dbcfb8, $alpha: 1);
    background-color: #ffffff;
    box-shadow: 2px 0 3px #354517;
    &:hover {
      background-color: #ffffff;
    }
  }
}
.popover-opener {
  cursor: pointer;
  font-weight: 500;
}
.simple-popover-dropdown-wrapper {
  &.hover {
    pointer-events: none;
    .popover-list {
      max-height: unset;
    }
  }
  .popover-list {
    max-width: 340px;
    min-width: 260px;
    padding: 8px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    position: relative;
    overflow: visible;
    max-height: 220px;
    overflow-y: auto;
    .popover-list-item {
      letter-spacing: 0px;
      padding: 6px 8px;
      > h4 {
        margin: 0;
        color: #805537;
        font-size: 12px;
        line-height: 22px;
        font-weight: bold;
      }
      > p {
        margin: 0px;
        font-size: 14px;
        line-height: 22px;
        color: #4d4f5c;
        display: flex;
      }
      &.owner-item {
        position: relative;
        padding-left: 24px;
        &::before {
          content: "";
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: 10px;
          height: 13px;
          display: inline-block;
          position: absolute;
          left: 10px;
          top: 10px;
        }
      }
      .popover-list-item-inner {
        display: flex;
        span.inner-title {
          font-weight: 500;
          padding-right: 5px;
          white-space: nowrap;
        }
      }
    }
  }
  .MuiPopover-paper {
    overflow: visible;
    margin-top: 8px;
    margin-bottom: 0px;
    &::before {
      content: "";
      position: absolute;
      width: 0px;
      height: 0px;
      left: 50%;
      top: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #ddd;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transform: translateY(-19px) translateX(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      width: 0px;
      height: 0px;
      left: 50%;
      top: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #fff;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transform: translateY(-19px) translateX(-50%);
    }
  }
}

// Page Not Found
.page-not-found {
  text-align: center;
  .MuiTypography-h1 {
    letter-spacing: 0;
    color: #969797;
    font-size: 39px;
    line-height: 52px;
    font-weight: 500;
  }
  .MuiTypography-h4 {
    letter-spacing: 0;
    color: #969797;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}

// My CSS Code
.loan-login-pg{
  .cus-captcha-cls {padding:0!important;
    .MuiGrid-root.MuiGrid-item{left:0;}
  }
  .col-sec{
    flex-direction: column;
    align-items: flex-start;
    .MuiGrid-grid-xs-12{
      &.MuiGrid-item{width:100%;}
    }
  }
}
span.MuiFormLabel-asterisk.MuiInputLabel-asterisk{color:red;}
.main-logo{
  display: flex;
  align-items: center;
  
  img{
    padding-right:12px;
    border-right:solid 1px #ddd;
    margin-right:12px;
  }
  .MuiTypography-h6{
    font-size: 1.1rem;
  }
}

// .sub-header{
//   &.subheader-bg{
//     flex-direction: column;
//     background: transparent url(./assets/subheader-bg.png) repeat-x bottom center;
//     padding-left: 1rem;
//     z-index: 99999;
//     .MuiTypography-h5{
//       padding-top: 0.75rem;
//       line-height: 1;
//     }
//   }
// }

// .main-container {
//   .dashboard-container {
//     &.loan-pg-wrapper {
//       max-width: 835px;

//       .MuiInputLabel-shrink {
//         transform: translate(0, 1.5px) scale(0.85);
//       }
//     }
//   }
// }
.logo-box {
  &.loan-pg {
    margin-bottom: 40px !important;
  }
}
.loan-first-pg {
  .grid-row {
    margin-bottom: 25px !important;
    .MuiTypography-gutterBottom {
      margin-bottom: 0.35em;
    }
  }
  .right-img-sec {
    img {
      margin-top: -50px;
      margin-left: 40px !important;
    }
  }
}
// .application-detail-container {
//   &.loan-pg-inner-wrapper {
//     flex-direction: column;
//     height: 100%;

//     &:last-child {
//       padding-bottom: 100px;
//     }
//   }
// }
.add-parent-sec {
  width: 100%;
  display: flex;
  align-items: center;
  .MuiGrid-item {
    padding: 16px;
    box-sizing: border-box;
    padding-right: 32px;
  }
}
.add-sec {
  .MuiButton-text {
    background: transparent;
    text-transform: capitalize;
    color: #1e41fd;
    font-size: 14px;

  }
}
.detail-section {
  &.loan-pg {
    &.padd-10 {
      padding-top: 5px;
    }
    .heading-section {
      margin-bottom: 0;
      .heading-title {
        display: flex;
        justify-content: space-between;
        background: #ededed;
        padding: 14px 32px;
        align-items: center;
        height: 54px;
        box-sizing: border-box;
        h5 {
          color: #333;
          font-size: 19px;
          font-weight: 500;
          // padding-left:32px;
          margin: 0;
        }
        div {
          .MuiFab-primary {
            color: #1e41fd;
            background-color: #fff;
          }
          .MuiSwitch-colorPrimary.Mui-checked {
            color: #1e41fd;
          }
          .MuiFab-sizeSmall {
            width: 36px;
            height: 36px;
          }
          .MuiButton-text {
            background: transparent;
            text-transform: capitalize;
            color: #1e41fd;
            font-size: 14px;
          }
        }
      }
    }
    .loan-pg-sec {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
        color: red;
      }
      .MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(0.85);
      }
      .MuiPaper-root {
        padding: 32px 32px 48px;
        width: calc(100% - 4px);
        box-sizing: border-box;
        // .MuiGrid-root{
        //   & .MuiGrid-container{
        //     .doc-list-sec{

        //     }
        //   }
        // }
      }
    }
  }
}
.img-list-sec {
  padding-top: 0 !important;
  .upload-img-sec {

    ul {
      li {
        max-width: 104px;
        width: 100% !important;
        height: 104px !important;
        .MuiImageListItem-item {
          .MuiImageListItemBar-root {
            height: 30px;
            .MuiImageListItemBar-actionIcon {
              .MuiIconButton-root {
                color: #cfc4c4;
                margin-left: -50%;
              }
            }
          }
        }
      }
    }
  }
}
.btn-group{
  .MuiButton-containedPrimary,
  .MuiButton-text{border-radius: 30px;}
}

.cus-btn-grp {
  .MuiButton-contained {
    padding: 7px 20px 6px;
    margin-right: 10px;
  }
  .MuiButton-text {
    padding: 7px 20px 6px;
  }
}

.loan-pg-sec {
  .txt-center {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  h5 {
    strong {
      color: #03306c;
      padding-bottom: 20px;
      display: inline-flex;
    }
  }
}

// .cusFullWidthCls{
//   width:100%;
//   .MuiFormControl-root{width: 100%;}
// }
.doc-list-sec {
  .MuiSnackbar-root {
    .MuiSnackbarContent-root {
      max-width: 500px;
      width: 100%;
      padding: 10px 24px!important;
      justify-content: space-between;
      .MuiSnackbarContent-message{
        width: calc(100% - 50px);
        .success, .error {
          white-space: pre;
        }
      }
      .MuiSnackbarContent-action{margin:0; padding:0}
    }
  }
  display: flex; flex-direction: column; position:relative;
  .sweet-loading{position: absolute; top:85px;}
  .GridStyle{
    .MuiGrid-root{
      &.MuiGrid-item{
        .UploadzoneArea-dropZone-4{
          border:0;
          .newDropzoneTextStyle{
            background: #ededed;
            border: solid 2px #b7b4b4;
            border-radius: 4px;
            svg, p{color:#1e41fd;}
          }
        }
      }
    }
  }
  .MuiGrid-root{
    margin-bottom: 2.5rem;
    .dropzone-file-wrapper{max-width: 350px; width:100%;
      .newDropZone{
        border: 1px solid #939292;
        .newDropzoneTextStyle{color:#1e41fd; background-color: rgba(3, 48, 108, 0.05);
          svg{color:#1e41fd; transform: rotate(45deg);}
        }
      }
      }
  }
  .newPreviewStyle{
    display: flex; flex-direction: row;
    .MuiGrid-root{
      &.MuiGrid-item{display: inline-flex;
        max-width: 25%;
        width: 25%;
        padding-right: 2rem;
        box-sizing: border-box;}
    }
  }
}
.captcha-sec-title{
  padding: 2.5rem 2.5rem 0.5rem!important; color:#03306C; font-size: 13px; font-weight: 400;
  h6{font-size: inherit;}
}
.cus-captcha-cls{
  display: flex; align-items: center; padding: 0.25rem 2.5rem 0.25rem!important; position: relative;
  .captcha-field{width:100%; max-width: 150px; margin-right: 2rem;}
  .MuiGrid-root{
    &.MuiGrid-item {position: absolute; left:34px; bottom: -1.25rem;}
  }
  .captcha-box{width:100%; max-width: 200px; margin-right: 1rem;}
  .icon-box{width:100%; max-width: 50px;}
}

.MuiFormControl-root{width: 100%;}

.no-bottom-line{
  .MuiInput-underline{
    &:before{
      border-bottom: 1px solid transparent;
    }
    &:after,
    &:not(.Mui-disabled):before,
    &:hover:not(.Mui-disabled):before{
      border-bottom: 2px solid transparent;
    }
  }

}

.no-bottom-spcs{
  padding-bottom: 4px !important;
  padding-top: 1.25rem!important;
}

.MuiLink-button{color: #1e41fd!important;
  font-size: 14px!important;
  text-decoration: none!important;
  margin-left: 8px!important;}
// Upload first image- remove delete icon
// Add fields in last 2 section at Application page
// Someimages needs to be replaced once Sanjay provide

.successBarStrip:after {
  background-color: #608c1a;
  content: "";
  width: calc(100% + 2px);
  height: 3px;
  position: absolute;
  bottom: -1px;
  left: -1px;
  border-radius: 0 0 4px 4px;
  transition: background-color .15s ease-in;
}

.redTxt {
  color: red;
  font-size: smaller;
}

.inValid .MuiInput-formControl:before {
  border-bottom: 1px solid red !important;
}

.captcha-box {
  flex: 1;
  img {
    border: 1px solid #A8BAD0;
    border-radius: 2px;
    max-width: 100%;
    height: 40px;
  }
}

p.PreviewList-uploadedFileName-16 {
  font-family: inherit;
  font-size: 13px;
  font-weight: 400;
}
.PreviewList-iconContainer-17{background-color: #465ddb;}

.customSnackbar{
  .MuiSnackbarContent-root{
    background-color: #4caf50; color:#fff; font-size: 14px;
    font-family: inherit;
    & .err-cls{
      background-color: #f44236;
    }
    .MuiSnackbarContent-message{
      .snackbarMessage{
        .messIcon{
          img{margin-right:1rem;}
        }
      }
    }
    // .MuiSnackbarContent-action{}
  }
}

.MuiDialog-paperWidthSm{
  
    max-width: 500px!important;
    width: 100%;
    max-height: fit-content;
    .wd500{
      .confirmation-dialog-content-section{
        .popupBgPic{
          background-color: #03306c;
          text-align: center;
          img{width:100%; max-width: 220px;
            margin-left: -20px;}
        }
        .bottom-section{
          padding: 1rem 2rem;
          text-align: center;
          h6{
            margin-bottom:1rem;
          }
        }
      }
    }
  }
// .doc-list-sec{ 
//   .MuiSnackbar-root {
//     .MuiSnackbarContent-root {
//       max-width: 500px;
//       width: 100%;
//       padding: 10px 24px!important;
//       justify-content: space-between;
//       .MuiSnackbarContent-message{
//         width: calc(100% - 50px);
//         .success, .error {
//           white-space: pre;
//         }
//       }
//       .MuiSnackbarContent-action{margin:0; padding:0}
//     }
//   }
// }
  .MuiInputAdornment-positionStart{color: #bdbaba;}
.upload-img-sec{
  position: relative;
  .error, .success{
    .MuiPaper-root{
     max-width: 500px;
    
     width:100%!important;
      padding: 10px 24px!important;
      justify-content: space-between;
      .MuiSnackbarContent-message{
        width: calc(100% - 50px);
        .success, .error {
          white-space: pre;
        }
      }
      .MuiSnackbarContent-action{margin:0; padding:0}
    }
  }
  .imagesContainer{
    display: flex; align-items: center;
    .image-dropzone-wrapper{cursor: pointer;}
    .sweet-loading{
      position: absolute;
      left: 1px;
      top: 110px;
    }
    .singleImage{
      width: 105px !important;
    height: 105px !important;
    margin-right: 15px;
    margin-top: -5px;
    position: relative;
    border: solid 1px #ededed;
      img{max-width:105px!important; max-height: 105px!important; width:100%; }
    .deleteButton{cursor: pointer;}
    }
  }
}