.imagesContainer{
    display: inline-block;
}
.imagesContainer div{
    display: inline-block;
    position: relative;
}
.singleImage .deleteButton{
    position: absolute;
    z-index: 9;
    height: 30px;
    background: black;
    width: 100% !important;
    text-align: center;
    display: none !important;
    top: 0;
    left: 0;
}
.singleImage:hover .deleteButton{
    display: block !important;
}

.singleImage .deleteButton span.delete-icon {
    width: 13px;
    height: 16px;
    background-image: url('../../assets/delete-property-icon.svg');
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 7px;
}
.imagesContainer .deleteButton img{
    margin-top: 7px;
    cursor: pointer !important;
}
/* .DropzoneArea-dropZone-342{
    display: inline-block !important;
    overflow: hidden !important;
}
[class^="DropzoneArea-dropZone-"] , [class^="jss"] {
    display: inline-block !important;
    overflow: hidden !important;
} */


.uploadingProgress {
    position: relative;
    display: inline-block;
    margin: 10px 0 10px 16px;
    width: auto;
  }
  
  .uploadingProgress .uploadingText {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: #717171;
    padding-top: 3px;
    padding-right: 10px;
    letter-spacing: 0.02857em;
    display: inline-block;
  }
  
  .uploadingProgress .dummyLoader {
    width: 20px;
    display: inline-block;
  }
  
  .uploadingProgress .dummyLoader .sweet-loading > div {
    top: 0 !important;
  }

  /* ================ Upload Property Image Section styling ================ */

.upload-property-image-section .imagesContainer {
    display: flex;
    flex-wrap: wrap;
}

.upload-property-image-section .imagesContainer .image-dropzone-wrapper {
    width: 104px;
    border: 1px solid #DBDBDB;
    cursor: pointer;
    height: 104px;
    margin: 3px;
    display: flex;
    position: relative;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
    box-sizing: border-box;
    min-height: 104px;
    background-color: #F4F4F4;
}
.upload-property-image-section .image-dropzone-wrapper .image-dropzone-uploader {
    color: #484848;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 12px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.upload-property-image-section .image-dropzone-wrapper .image-dropzone-uploader img.image-dropzone-icon {
    margin-bottom: 5px;
    max-width: 18px;
}
.image-dropzone-wrapper .image-dropzone-uploader svg.image-dropzone-icon {
    color: #484848;
    width: 30px;
    height: 30px;
    opacity: 0.49;
}
.image-dropzone-wrapper .image-dropzone-uploader p.image-dropzone-text {
    margin: 0;
    /* opacity: 0.49; */
    padding: 0 10px 0 0;
    font-size: 12px;
    line-height: 1.2;
}
/* .upload-property-image-section .imagesContainer > div:not(.image-dropzone-wrapper) {
    width: calc(100% - 110px);
    display: flex;
    flex-wrap: wrap;
} */
.upload-property-image-section .imagesContainer > .sweet-loading {
    width: auto;
    display: block;
    
}
.upload-property-image-section .imagesContainer .singleImage {
    width: 104px;
    border: 1px solid #DBDBDB;
    cursor: pointer;
    height: 104px;
    margin: 3px;
    display: flex;
    position: relative;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
    box-sizing: border-box;
    min-height: 104px;
    background-color: #F4F4F4;
}

.upload-property-image-section .imagesContainer .singleImage .dropzone-img {
    /* width: 100%;
    height: 100%; */
}

/* ================ End Upload Property Image Section styling ================ */

.custom-dialog-wrapper .confirmation-dialog-content-section {
    padding: 0 !important;
}


/* ================ Lightbox styling section ================ */

div#SRLLightbox .SRLControls > button:not(.SRLCloseButton) {
    display: none;
}

/* ================ End Lightbox styling section ================ */

/* ================ Snackbar styling section ================ */

.upload-property-image-section .MuiSnackbar-root .MuiSnackbarContent-root {
    min-height: 80px;
    min-width: 600px;
    padding-left: 20px;
    background: #608c1a url(../../assets/art.svg) left bottom no-repeat;
    position: relative;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.upload-property-image-section .MuiSnackbar-root .MuiSnackbarContent-root .MuiSnackbarContent-message {
    font-size: 1rem;
    font-weight: 300;
    width: 90%;
}
.upload-property-image-section .MuiSnackbar-root .MuiSnackbarContent-root .MuiSnackbarContent-message span#client-snackbar {
    position: relative;
}
.upload-property-image-section .MuiSnackbar-root .MuiSnackbarContent-root .MuiSnackbarContent-message span#client-snackbar:before {
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
}
.upload-property-image-section .MuiSnackbar-root .MuiSnackbarContent-root .MuiSnackbarContent-message span#client-snackbar svg {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    text-align: center;
    line-height: 46px;
    margin-right: 3%;
    opacity: 1;
}
.upload-property-image-section .MuiSnackbar-root .MuiSnackbarContent-root .MuiSnackbarContent-action > button {
    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;
    text-decoration: underline;
    padding: 12px;
}
.upload-property-image-section .MuiSnackbar-root .MuiSnackbarContent-root .MuiSnackbarContent-action > button svg {
    font-size: 1.5rem;
}

.upload-property-image-section .MuiSnackbar-root.success .MuiSnackbarContent-root .MuiSnackbarContent-message span#client-snackbar:before {
    background-image: url(../../assets/comment_check.svg);
}
.upload-property-image-section .MuiSnackbar-root.error .MuiSnackbarContent-root .MuiSnackbarContent-message span#client-snackbar:before {
    background-image: url(../../assets/error_toaster.svg);
}
.upload-property-image-section .MuiSnackbar-root.success .MuiSnackbarContent-root .MuiSnackbarContent-message span#client-snackbar svg {
    background-color: #fff;
}
.upload-property-image-section .MuiSnackbar-root.success .MuiSnackbarContent-root .MuiSnackbarContent-message span#client-snackbar svg > path {
  display: none;
}

/* ================ End Snackbar styling section ================ */



/* ================ Start Custom Lightbox section ================ */
.custom-lightbox-wrapper .MuiDialog-paperScrollPaper {
    background-color: transparent;
    box-shadow: none;
    overflow: hidden;
}
.custom-lightbox-wrapper .lightbox-header button#crossIcon {
    background: transparent;
    overflow: hidden;
    position: fixed;
    right: 8px;
    top: 8px;
    color: #fff;
    padding: 5px;
}

.custom-lightbox-wrapper .lightbox-footer.thumbnail-wrapper {
    position: fixed;
    width: 100%;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0px;
    flex-wrap: wrap;
    max-height: 80px;
    overflow: hidden;
    max-width: 600px;
    margin: 0 auto;
}

.custom-lightbox-wrapper .lightbox-footer.thumbnail-wrapper .thumbnail-item {
    position: relative;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
    box-sizing: border-box;
    background-color: transparent;
    margin: 2px;
    max-width: 55px;
    display: flex;
    opacity: 0.4;
    transition: 0.3s ease;
    will-change: opacity;
    cursor: pointer;
}
.custom-lightbox-wrapper .lightbox-footer.thumbnail-wrapper .thumbnail-item:hover {
    opacity: 1;
}

.custom-lightbox-wrapper .lightbox-body img.imageSize {
    max-width: 100%;
}

.custom-lightbox-wrapper .lightbox-footer.thumbnail-wrapper .thumbnail-item img {
    width: auto;
    max-width: 100%;
}

.lightbox-controls-wrapper .next-prev-section button {
    background-color: transparent;
    height: auto;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 9px 12px;
    min-width: 60px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%) !important;
    display: flex;
    align-items: center;
    z-index: 999;
}
.lightbox-controls-wrapper .next-prev-section button.back-arrow {
    left: 0;
}
.lightbox-controls-wrapper .next-prev-section button.fordward-arrow {
    right: 0;
    transform: rotateZ(180deg) translateY(50%) !important;
}
.lightbox-controls-wrapper .next-prev-section button img {
    width: 24px;
}
.lightbox-controls-wrapper .next-prev-section button.Mui-disabled {
    opacity: 0.4;
}
/* ================ End Custom Lightbox section ================ */


